import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
	deletingRequestSuccess,
	editRequestSuccess,
	createRequestError,
	createRequestSuccess,
	requestsLoaded,
	startDeletingRequest,
	startEditRequest,
	startCreateRequest,
	startLoadingRequests,
	updateRequest,
	requestsFailedToLoad,
	loadDefaultRequests,
	openRequestWorkspaceFromOutside,
	deleteRequests,
	addRequest,
	openNewRequestFromOutside,
} from '@app/pages/cmms/components/request/state/requests.action';
import { RequestsService } from '@app/pages/cmms/services/requests.service';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { AuErrorService } from '@core/services/au-error-service';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';
import { AuErrorAdapter } from '@app/shared';
import { Store } from '@ngrx/store';
import { IRequestState } from '@app/pages/cmms/components/request/state/requests.reducer';
import { requestsQueryVariables } from '@app/pages/cmms/components/request/state/requests.selectors';
import { IRequest } from '@app/pages/cmms/interfaces/request.interfaces';
import { defaultPageSize } from '@app/pages/cmms/configs/pagination-query-variables';

@Injectable()
export class RequestsEffects {
	constructor(
		private actions$: Actions,
		private requestService: RequestsService,
		private notify: IconSnackBarService,
		private sidebarService: AuRightSidebarService,
		private errorService: AuErrorService,
		private store$: Store<IRequestState>
	) {}

	requestOpened$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadDefaultRequests),
			map(() => {
				return startLoadingRequests({ variables: {} });
			})
		);
	});

	startLoadingRequests$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startLoadingRequests),
			map(({ variables }) => {
				if (variables?.first || variables?.last) {
					return variables;
				}
				return {
					...variables,
					first: defaultPageSize,
				};
			}),
			switchMap(variables => {
				return this.requestService.loadRequests(variables).pipe(
					map(({ requestPagination, requests }) => {
						return requestsLoaded({ requestPagination, requests });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return of(requestsFailedToLoad());
					})
				);
			})
		);
	});

	startCreateRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startCreateRequest),
			exhaustMap(({ variables, skipTableReload }) => {
				return this.requestService.createRequests(variables).pipe(
					map(({ success, request }) => {
						if (success) {
							this.sidebarService.closeAndClearPortal();
							return createRequestSuccess({ request, skipTableReload });
						} else {
							return createRequestError({});
						}
					}),
					catchError(error => {
						return of(createRequestError({ error }));
					})
				);
			})
		);
	});

	createRequestSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(createRequestSuccess),
			withLatestFrom(this.store$.select(requestsQueryVariables)),
			map(([requestData, variables]) => {
				this.notify.success('Request has been created successfully');
				return requestData.skipTableReload
					? addRequest({ request: requestData.request })
					: startLoadingRequests({ variables });
			})
		);
	});

	createRequestError$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(createRequestError),
				map(({ error }) => {
					if (error) {
						this.errorService.notifyUserAboutError({
							error: error,
							defaultMessage:
								'Something went wrong. Please try again later or report an issue to support',
						});
					}
					this.notify.error(
						'Something went wrong. Please try again later or report an issue to support'
					);
				})
			);
		},
		{ dispatch: false }
	);

	startEditRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startEditRequest),
			exhaustMap(({ request }) => {
				return this.requestService.editRequest(request).pipe(
					map(({ request }) => {
						this.notify.success('Request has been updated successfully');
						return editRequestSuccess({ request });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	editRequestSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(editRequestSuccess),
			map(({ request }) =>
				updateRequest({
					update: {
						// this is id of entity adapter!!! this code is correct;
						id: request.pk,
						changes: request,
					},
				})
			)
		);
	});

	startDeletingRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startDeletingRequest),
			mergeMap(({ pk }) => {
				return this.requestService.deleteRequest(pk).pipe(
					map(() => {
						this.sidebarService.close();
						return deletingRequestSuccess({ pk });
					})
				);
			})
		);
	});

	deletingRequestSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(deletingRequestSuccess),
			withLatestFrom(this.store$.select(requestsQueryVariables)),
			map(([_, variables]) => {
				this.notify.success('Request has been deleted successfully');
				return startLoadingRequests({ variables });
			})
		);
	});

	deleteMultipleRequests$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteRequests),
			withLatestFrom(this.store$.select(requestsQueryVariables)),
			mergeMap(([{ pks }, variables]) => {
				return this.requestService.deleteRequests(pks).pipe(
					map(() => {
						const multiple = pks?.length > 1;
						this.notify.success(
							`${multiple ? pks?.length + ' ' : ''}Request${multiple ? 's' : ''} ${
								multiple ? 'have' : 'has'
							} been deleted successfully`
						);
						return startLoadingRequests({ variables });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	openRequestWorkspaceFromOutside$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openRequestWorkspaceFromOutside),
				exhaustMap(({ pk }) => {
					return this.requestService.getRequestByPk(pk);
				}),
				map((request: IRequest) => {
					// If Request was deleted server will return null, so we check it and notify user
					if (!request) {
						this.notify.error('Object does not exist');
					} else {
						this.requestService.viewRequestInWorkspace(request);
					}
				}),
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);

	openNewRequestWorkspaceFromOutside$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openNewRequestFromOutside),
				map(() => {
					return this.requestService.openNewRequestWorkspace();
				})
			);
		},
		{ dispatch: false }
	);
}
