import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap, of } from 'rxjs';
import { AuErrorAdapter } from '@app/shared';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WorkOrdersService } from '@app/pages/cmms/services/work-orders.service';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';
import { AuErrorService } from '@core/services/au-error-service';
import {
	addWorkOrder,
	createWorkOrderError,
	createWorkOrderSuccess,
	deleteWorkOrders,
	deletingWorkOrderSuccess,
	loadDefaultWorkOrders,
	openNewWorkOrderFromOutside,
	openWorkOrderWorkspaceFromOutside,
	startCreateWorkOrder,
	startDeletingWorkOrder,
	startLoadingWorkOrders,
	startUpdateWorkOrder,
	updateWorkOrder,
	updateWorkOrderSuccess,
	workOrdersFailedToLoad,
	workOrdersLoaded,
} from '@app/pages/cmms/components/work-order/state/work-order.actions';
import { workOrderQueryVariables } from '@app/pages/cmms/components/work-order/state/work-order.selectors';
import { Store } from '@ngrx/store';
import { IWorkOrderState } from '@app/pages/cmms/components/work-order/state/work-order.reducer';
import { defaultPageSize } from '@app/pages/cmms/configs/pagination-query-variables';

@Injectable()
export class WorkOrderEffects {
	constructor(
		private actions$: Actions,
		private notify: IconSnackBarService,
		private sidebarService: AuRightSidebarService,
		private workOrdersService: WorkOrdersService,
		private errorService: AuErrorService,
		private store$: Store<IWorkOrderState>
	) {}

	workOrdersOpen$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadDefaultWorkOrders),
			map(() => startLoadingWorkOrders({ variables: {} }))
		);
	});

	startLoadingWorkOrders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startLoadingWorkOrders),
			map(({ variables }) => {
				if (variables?.first || variables?.last) {
					return variables;
				}
				return {
					...variables,
					first: defaultPageSize,
				};
			}),
			switchMap(variables => {
				return this.workOrdersService.loadWorkOrders(variables).pipe(
					map(({ workOrderPagination, workOrders }) => {
						return workOrdersLoaded({ workOrderPagination, workOrders });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return of(workOrdersFailedToLoad());
					})
				);
			})
		);
	});

	startCreateWorkOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startCreateWorkOrder),
			exhaustMap(({ workOrder, skipTableReload }) => {
				return this.workOrdersService.createWorkOrder(workOrder).pipe(
					map((value: { success: boolean; workOrder: IWorkOrder }) => {
						if (value.success) {
							this.sidebarService.closeAndClearPortal();
							return createWorkOrderSuccess({
								workOrder: value.workOrder,
								skipTableReload,
							});
						} else {
							return createWorkOrderError({});
						}
					}),
					catchError(error => {
						return of(createWorkOrderError({ error }));
					})
				);
			})
		);
	});

	createWorkOrderSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(createWorkOrderSuccess),
			withLatestFrom(this.store$.select(workOrderQueryVariables)),
			map(([workOrderData, variables]) => {
				this.notify.success('Work Order has been created successfully');
				this.workOrdersService.updateCalendarData$.next();

				return workOrderData.skipTableReload
					? addWorkOrder({ workOrder: workOrderData.workOrder })
					: startLoadingWorkOrders({ variables });
			})
		);
	});

	createWorkOrderError$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(createWorkOrderError),
				map(({ error }) => {
					if (error) {
						this.errorService.notifyUserAboutError({
							error: error,
							defaultMessage:
								'Something went wrong. Please try again later or report an issue to support',
						});
					}
					this.notify.error(
						'Something went wrong. Please try again later or report an issue to support'
					);
				})
			);
		},
		{ dispatch: false }
	);

	startUpdateWorkOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startUpdateWorkOrder),
			withLatestFrom(this.store$.select(workOrderQueryVariables)),
			exhaustMap(([{ workOrder }, workOrderQueryVariables]) => {
				return this.workOrdersService.updateWorkOrder(workOrder as any).pipe(
					map(workOrderSuccess => {
						if (workOrder['needUpdateTable']) {
							this.store$.dispatch(
								startLoadingWorkOrders({ variables: { ...workOrderQueryVariables } })
							);
						}
						return updateWorkOrderSuccess({
							workOrder: workOrderSuccess.data.editWorkOrder.workOrder,
						});
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	updateWorkOrderSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(updateWorkOrderSuccess),
			map(({ workOrder }) => {
				this.notify.success('Work Order has been updated successfully');
				this.workOrdersService.updateCalendarData$.next();
				return updateWorkOrder({
					update: {
						// this is id of entity adapter!!! this code is correct;
						id: workOrder.pk,
						changes: workOrder,
					},
				});
			})
		);
	});

	startDeletingWorkOrder$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(startDeletingWorkOrder),
			mergeMap(({ pk }) => {
				return this.workOrdersService.deleteWorkOrder(pk).pipe(
					map(() => {
						this.sidebarService.close();
						return deletingWorkOrderSuccess({ pk });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	deletingWorkOrderSuccess$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(deletingWorkOrderSuccess),
			withLatestFrom(this.store$.select(workOrderQueryVariables)),
			map(([_, variables]) => {
				this.notify.success('Work Order has been deleted successfully');
				this.workOrdersService.updateCalendarData$.next();
				return startLoadingWorkOrders({ variables });
			})
		);
	});

	deleteMultipleWorkOrders$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(deleteWorkOrders),
			withLatestFrom(this.store$.select(workOrderQueryVariables)),
			mergeMap(([{ pks }, variables]) => {
				return this.workOrdersService.deleteWorkOrders(pks).pipe(
					map(() => {
						const multiple = pks?.length > 1;
						this.notify.success(
							`${multiple ? pks?.length + ' ' : ''}Work Order${multiple ? 's' : ''} ${
								multiple ? 'have' : 'has'
							} been deleted successfully`
						);
						return startLoadingWorkOrders({ variables });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	openWorkOrderWorkspaceFromOutside$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openWorkOrderWorkspaceFromOutside),
				exhaustMap(({ pk }) => {
					return this.workOrdersService.getWorkOrderByPk(pk);
				}),
				map((workOrder: IWorkOrder) => {
					// If WO was deleted server will return null, so we check it and notify user
					if (!workOrder) {
						this.notify.error('Object does not exist');
					} else {
						this.workOrdersService.viewWorkOrderInWorkspace(workOrder);
					}
				}),
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);

	opeNewWorkOrderWorkspaceFromOutside$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openNewWorkOrderFromOutside),
				map(() => {
					return this.workOrdersService.openNewWorkOrderWorkspace();
				})
			);
		},
		{ dispatch: false }
	);
}
